import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { LinkTextWithThumbnail } from "../../../../components/Link"

const StyledContainer = styled.div`
  border: 1px solid #027033;
  padding: 1.6rem 2rem;
  margin-block-start: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h6 {
    color: #027033;
    font-size: 1.1rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 657px) {
      text-align: center;
      margin-inline: auto;
    }
    @media screen and (max-width: 410px) {
      font-size: 0.9375rem;
    }
  }
`

const StyledLinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 880px) {
    flex-direction: column;
    row-gap: 2rem;
  }
  @media screen and (max-width: 600px) {
    row-gap: 3rem;
  }
  /* margin-block-start: 1rem;
  margin-inline: 2rem; */
`

const StyledImageContainer = styled(Image)`
  width: 140px;
  /* margin-inline-start: 1rem; */
`

export default function InitiativeOfFoodLoss({ data }) {
  const {
    title,
    link: { soygood, milkSmile },
  } = data
  const { soygoodLogo, milkSmileLogo } = useStaticQuery(graphql`
    query {
      soygoodLogo: file(relativePath: { eq: "soygood-logo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      milkSmileLogo: file(relativePath: { eq: "gyunyu_logo.jpg" }) {
        childImageSharp {
          fluid(quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledContainer>
      <h6>【{title}】</h6>
      <StyledLinkContainer>
        <LinkTextWithThumbnail
          href={soygood.url}
          text={soygood.text}
          thumbnailComponent={
            <StyledImageContainer
              fluid={soygoodLogo.childImageSharp.fluid}
              alt="SOYGOOD logo"
            />
          }
        />
        <LinkTextWithThumbnail
          href={milkSmile.url}
          text={milkSmile.text}
          thumbnailComponent={
            <StyledImageContainer
              fluid={milkSmileLogo.childImageSharp.fluid}
              alt="牛乳でスマイルプロジェクトのロゴ"
            />
          }
        />
      </StyledLinkContainer>
    </StyledContainer>
  )
}
