import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import {
  LinkTextWithArrow,
  LinkTextWithPDFDownload,
} from "../../../../components/Link"

const StyledFlexbox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1rem auto;
`
export default function FoodAdditivesInternalLinks({ inHouseStd, qa }) {
  const { pdf } = useStaticQuery(graphql`
    query {
      pdf: file(relativePath: { eq: "フランソア食品添加物自社基準.pdf" }) {
        publicURL
      }
    }
  `)
  return (
    <StyledFlexbox>
      <LinkTextWithPDFDownload href={pdf.publicURL} text={inHouseStd.text} />
      <LinkTextWithArrow href={qa.url} text={qa.text} />
    </StyledFlexbox>
  )
}
