import React from "react"
import DetailsContainer from "../DetailsContainer"
import Headline from "../Headline"
import Paragraph from "../Paragraph"
import FoodAdditivesListLink from "./FoodAdditivesListLink"
import FoodAdditivesInternalLinks from "./FoodAdditivesInternalLinks"
import Image from "../Image"

export default function FoodAdditivesWithStrictEyes({
  headline,
  image,
  imageHeading,
  details,
  link,
}) {
  const { paragraph1, paragraph2 } = details
  const [foodAdditivesListLink, inHouseStd, qa] = link

  return (
    <>
      <Image
        id="food-additives-with-strict-eyes"
        image={image}
        imageHeading={imageHeading}
      />
      <Headline text={headline} />
      <DetailsContainer>
        <Paragraph>{paragraph1.line1}</Paragraph>
        <FoodAdditivesListLink
          href={foodAdditivesListLink.url}
          text={foodAdditivesListLink.text}
          additionalText={foodAdditivesListLink.additionalText}
        />
        <Paragraph>{paragraph2.line1}</Paragraph>
        <FoodAdditivesInternalLinks inHouseStd={inHouseStd} qa={qa} />
      </DetailsContainer>
    </>
  )
}
