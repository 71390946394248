import React from "react"
import styled from "styled-components"

const StyledNotification = styled.span`
  font-size: 12px;
  display: block;
`
export default function Notification({ text }) {
  return <StyledNotification>{text}</StyledNotification>
}
