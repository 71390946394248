import React from "react"
import DetailsContainer from "../DetailsContainer"
import Headline from "../Headline"
import Paragraph from "../Paragraph"
import ExampleOfInitiatives from "./ExampleOfInitiatives"
import InitiativeOfFoodLoss from "./InitiativeOfFoodLoss"
import Image from "../Image"

export default function FaceToFaceDeliciousness({
  headline,
  image,
  imageHeading,
  details,
  exampleOfInitiatives: example,
  initiativeOfFoodLoss: foodLoss,
}) {
  const { paragraph1 } = details
  return (
    <>
      <Image
        id="face-to-face-deliciousness"
        image={image}
        imageHeading={imageHeading}
        color="#fff"
      />
      <Headline text={headline} />
      <DetailsContainer>
        <Paragraph>
          {paragraph1.line1}
          <br />
          {paragraph1.line2}
          <br />
          {paragraph1.line3}
        </Paragraph>
      </DetailsContainer>
      <ExampleOfInitiatives title={example.title} category={example.category} />
      <InitiativeOfFoodLoss data={foodLoss} />
    </>
  )
}
