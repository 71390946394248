import React from "react"
import Styled from "styled-components"

const StyledContainer = Styled.div`
  margin-block: 30px;
  font-weight: bold;
  h3 {
    font-size: 1.32em;
    line-height: 1.62em;
  }
  p {
    font-size: 1em;
    line-height: 1.62em;
    padding-left: .75rem;
  }
`

export default function Headline({ text, subText = "" }) {
  return (
    <StyledContainer>
      <h3>{text}</h3>
      {subText && <p>{subText}</p>}
    </StyledContainer>
  )
}
