import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  margin: 30px 0;
  line-height: 140%;
`
export default function DetailsContainer({ children }) {
  return <StyledContainer>{children}</StyledContainer>
}
