import { graphql, useStaticQuery } from "gatsby"

export default function useQuery() {
  const {
    mainVisual,
    foodAdditivesImage,
    preparationsImage,
    lowTransImage,
    faceToFaceImage,
    additivePdf,
  } = useStaticQuery(graphql`
    query {
      mainVisual: file(relativePath: { eq: "slowbread-raisin-and-bool.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      preparationsImage: file(relativePath: { eq: "walnut-seihou.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foodAdditivesImage: file(
        relativePath: { eq: "commitment_soygood2.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lowTransImage: file(relativePath: { eq: "commitment_abura.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faceToFaceImage: file(relativePath: { eq: "commitment_asagiri.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return {
    mainVisual,
    foodAdditivesImage,
    preparationsImage,
    lowTransImage,
    faceToFaceImage,
    additivePdf,
  }
}
