import React from "react"
import GatsbyImage from "gatsby-image"
import styled from "styled-components"

const StyledContainer = styled.div`
  /* margin: 0 auto; */
  width: 300px;
  position: relative;
  .image-container {
    width: 300px;
    height: 180px;
    /* height: 200px;
    width: 240px; */
    .img {
      position: absolute !important;
    }
  }

  @media screen and (max-width: 500px) {
    margin: 0 auto;
  }
`

const StyledImageHeading = styled.p`
  width: 100%;
  position: absolute;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  left: 50%;
  display: flex;
  row-gap: 0.5rem;
  flex-flow: column nowrap;
  font-size: 1.25rem;
  font-weight: bold;
  color: ${props => props.color || "inherit"};
  /* bottom: 5%; */
  align-items: center;
  ${props =>
    props.isBottom
      ? { bottom: "5%", transform: "translateX(-50%)" }
      : { top: "50%", transform: "translate(-50%, -50%)" }}
`

export default function Image({
  id = "",
  image,
  imageHeading = [],
  color,
  isBottom = false,
}) {
  return (
    <StyledContainer id={id}>
      <GatsbyImage
        fluid={image.childImageSharp.fluid}
        className="image-container"
        alt={imageHeading.join(",")}
      />
      <StyledImageHeading color={color} isBottom={isBottom}>
        {imageHeading.map((text, index) => (
          <span key={index}>{text}</span>
        ))}
      </StyledImageHeading>
    </StyledContainer>
  )
}
