import React from "react"
import styled from "styled-components"
import InitiativeMaterial from "./InitiativeMaterial"

const StyledContainer = styled.div`
  background-color: #027033;
  color: white;
  padding: 1.6rem 2rem;

  h6 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 657px) {
      text-align: center;
    }
    @media screen and (max-width: 410px) {
      font-size: 0.9375rem;
    }
  }
`
const StyledFlexbox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 2rem;
  @media screen and (max-width: 900px) {
    .item:nth-child(1) {
      order: 1;
    }
    .item:nth-child(2) {
      order: 3;
    }
    .item:nth-child(3) {
      order: 2;
    }
  }
  @media screen and (max-width: 657px) {
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    column-gap: 0;
    .item:nth-child(2) {
      order: 2;
    }
    .item:nth-child(3) {
      order: 3;
    }
  }
`

export default function ExampleOfInitiatives({ title, category }) {
  const { rice, flour, others } = category
  return (
    <StyledContainer>
      <h6>【{title}】</h6>
      <StyledFlexbox>
        <InitiativeMaterial
          title={flour.title}
          labels={flour.labels}
          className="item"
        />
        <InitiativeMaterial
          title={rice.title}
          labels={rice.labels}
          className="item"
        />
        <InitiativeMaterial
          title={others.title}
          labels={others.labels}
          className="item"
        />
      </StyledFlexbox>
    </StyledContainer>
  )
}
