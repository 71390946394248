import React from "react"
import styled from "styled-components"
import { IconContext } from "react-icons/lib"
import { FiExternalLink } from "react-icons/fi"

const StyledContainer = styled.div`
  margin: 1rem auto;
  width: 100%;
  text-align: start;
`

const StyledExternalLink = styled.a`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9em;
  column-gap: 1rem;
  color: #c30f23;
  transition: 0.3s;
  svg {
    transition: 0.3s;
  }
  :hover {
    opacity: 0.8;
    color: #e50012;
    svg {
      stroke: #e50012;
    }
  }
  :not(:first-of-type) span {
    margin-inline-end: 8px;
  }
`

const StyledInnerLinkText = styled.span`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
`

const StyledAdditionalText = styled.span`
  font-size: 12px;
`
export default function FoodAdditivesListLink({ text, additionalText, href }) {
  return (
    <StyledContainer>
      <StyledExternalLink href={href} target="_blank" rel="noopener noreferrer">
        <StyledInnerLinkText>
          {text}
          <StyledAdditionalText>{additionalText}</StyledAdditionalText>
        </StyledInnerLinkText>
        <IconContext.Provider
          value={{ color: "#c30f23", size: "1.4em", strokeWidth: "1px" }}
        >
          <FiExternalLink />
        </IconContext.Provider>
      </StyledExternalLink>
    </StyledContainer>
  )
}
