import React from "react"
import DetailsContainer from "./DetailsContainer"
import Headline from "./Headline"
import Paragraph from "./Paragraph"
import Notification from "./Notification"
import Image from "./Image"

export default function LowTransFattyAcids({
  headline,
  additionalText,
  image,
  imageHeading,
  details,
}) {
  const { paragraph1, paragraph2 } = details
  return (
    <>
      <Image
        id="low-trans-fatty-acids"
        image={image}
        imageHeading={imageHeading}
        isBottom={true}
      />
      <Headline text={headline} subText={additionalText} />
      <DetailsContainer>
        <Paragraph>{paragraph1.line1}</Paragraph>
        <Paragraph>
          {paragraph2.line1}
          <br />
          {paragraph2.line2}
          <br />
          {paragraph2.line3}
          <Notification text={paragraph2.notification} />
        </Paragraph>
      </DetailsContainer>
    </>
  )
}
