import React from "react"
import styled from "styled-components"
import PreparationOfRawMaterials from "./PreparationOfRawMaterials"
import { FoodAdditivesWithStrictEyes } from "./FoodAdditivesWithStrictEyes"
import LowTransFattyAcids from "./LowTransFattyAcids"
import { FaceToFaceDeliciousness } from "./FaceToFaceDeliciousness"
import ContentContainer from "../../../components/ContentContainer"
import { MainVisualWithCassis } from "../../../components/MainVisualWithCassis"
import { useQuery } from "../hooks"
import data from "../data.yaml"

const StyledContainer = styled.div`
  margin-block-end: 80px;
`

export default function Commitment() {
  const {
    mainVisual,
    foodAdditivesImage,
    preparationsImage,
    lowTransImage,
    faceToFaceImage,
  } = useQuery()

  const {
    title,
    subTitle,
    preparationOfRawMaterials: prep,
    foodAdditivesWithStrictEyes: foodAdditives,
    lowTransFattyAcids: lowTrans,
    faceToFaceDeliciousness: faceToFace,
  } = data
  return (
    <>
      <MainVisualWithCassis
        title={title}
        subTitle={subTitle}
        mainVisualSrcset={mainVisual.childImageSharp.fluid}
      />
      <ContentContainer>
        <StyledContainer>
          <PreparationOfRawMaterials
            headline={prep.headline}
            image={preparationsImage}
            imageHeading={prep.imageHeading}
            details={prep.details}
          />
        </StyledContainer>
        <StyledContainer>
          <FoodAdditivesWithStrictEyes
            headline={foodAdditives.headline}
            image={foodAdditivesImage}
            imageHeading={foodAdditives.imageHeading}
            details={foodAdditives.details}
            link={foodAdditives.link}
          />
        </StyledContainer>
        <StyledContainer>
          <LowTransFattyAcids
            headline={lowTrans.headline}
            additionalText={lowTrans.additionalText}
            image={lowTransImage}
            imageHeading={lowTrans.imageHeading}
            details={lowTrans.details}
          />
        </StyledContainer>
        <StyledContainer>
          <FaceToFaceDeliciousness
            headline={faceToFace.headline}
            image={faceToFaceImage}
            imageHeading={faceToFace.imageHeading}
            details={faceToFace.details}
            exampleOfInitiatives={faceToFace.exampleOfInitiatives}
            initiativeOfFoodLoss={faceToFace.initiativeOfFoodLoss}
          />
        </StyledContainer>
      </ContentContainer>
    </>
  )
}
