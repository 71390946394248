import React from "react"
import styled from "styled-components"

const StyledParagraph = styled.p`
  margin-block-end: 4px;
  @media screen and (max-width: 410px) {
    font-size: 0.875rem;
  }
`

const StyledContainer = styled.div``
const StyledUnorderList = styled.ul`
  line-height: 1.3;
  @media screen and (max-width: 410px) {
    font-size: 0.875rem;
  }
`

export default function InitiativeMaterial({
  title,
  labels = [],
  className = "",
}) {
  return (
    <StyledContainer className={className}>
      <StyledParagraph>{title}</StyledParagraph>
      <StyledUnorderList>
        {labels.map((label, index) => (
          <li key={index}>{label}</li>
        ))}
      </StyledUnorderList>
    </StyledContainer>
  )
}
