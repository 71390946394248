import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import { Commitment } from "../../features/commitment-page"

export default function OurPolicyCommitment({ location }) {
  React.useEffect(() => {
    if (window && location.hash == "") return
    window.scrollBy(0, -65)
  }, [])
  return (
    <Layout>
      <SEO>
        <title>私たちのこだわり｜フランソア</title>
        <meta name="description" content="		 	 		私たちのこだわり 		 	..." />
      </SEO>
      <Commitment />
    </Layout>
  )
}
